$(function(){
  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $(".to-page-top").fadeOut();
    } else {
      $(".to-page-top").fadeIn();
      $(".to-page-top a").smoothScroll({offset: -80});
    }
  }
  $(window).on("load scroll", displayToTop);

  // navbar
  $(".navbar-toggler").on('click',function(){
      $(".menu1").toggleClass("menuclick1")
  });
  $(".navbar-toggler").on('click',function(){
      $(".menu2").toggleClass("menuclick2")
  });
  $(".navbar-toggler").on('click',function(){
      $(".menu3").toggleClass("menuclick3")
  });
  // top
  if ($('body').hasClass('top')) {
    $('.slick').slick({
      autoplay: true,
      pauseOnHover: false,
      autoplaySpeed: 4000,
      speed: 1000,
      arrows:false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      infinite: true,
      centerMode:true,
      centerPadding: '30%',
      responsive: [
        {
          breakpoint: 768,
          settings: {
           centerPadding: '10%'
          }
        }
      ]
    });
    $('.block-link').click(function() {
      window.location = $(this).find('a').attr('href');
    });
  }
  // locations
  if ($('body').hasClass('locations-top')) {
    $('.contents .location-group .row div').click(function() {
      window.location = $(this).find('a').attr('href');
    });
  }
  if ($('body').hasClass('locations-detail')) {
    var cnt = $('.slide img').length;

    if (cnt < 2) {
      $('.slide').removeClass('slide').addClass('unslide');
    } else{
      $('.slide').addClass('slider').slick({
          autoplay: true,
          pauseOnHover: false,
          autoplaySpeed: 6000,
          speed: 1000,
          arrows:false,
          infinite: true,
          slidesToShow: 1,
           centerPadding: '20%',
          centerMode: true,
          responsive: [{
            breakpoint: 568,
            settings: {
              slidesToShow: 1,
               centerPadding: '20%'
            }
          }]
      });
    }

  }
  // menu
  if ($('body').hasClass('menu')) {
    $(".contents a").smoothScroll();
  }
  // about
  if ($('body').hasClass('about')) {
    $('.slider').slick({
      autoplay: true,
      pauseOnHover: false,
      autoplaySpeed: 4000,
      speed: 1000,
      arrows:false,
      infinite: true,
      slidesToShow: 3,
      centerMode: true,
      responsive: [{
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
           centerPadding: '20%'
        }
      }]
    });
  }
  // news
  if ($('body').hasClass('news-list')) {
    $('.entry').click(function(){
      window.location = $(this).find('a').attr('href');
     });
  }
  if ($('body').hasClass('news-detail')) {
    $(".contents .entry-body img").removeAttr('width height');
    $(".contents .entry-body a img").unwrap();
  }
  // contact
  if($('body').hasClass('contact-top')){
    $("#mail-form-shop").hide();
    $("input[name='mail_kind']").click(function () {
      testValue = $("input[name='mail_kind']:checked").val();
      if(testValue === 'ご意見・ご感想'){
        $("#mail-form-shop").css('display','flex');
      } else {
        $("#mail-form-shop select").val('');
        $("#mail-form-shop").hide();
      }
    });
  }
});

  $(window).on('load resize',function(){
    // menu-preference
    if ($('body').hasClass('menu-preference')) {
      // $(".preference-block").each(function(){
      //   var pbWidth = Math.floor($(this).width());
      //   $(this).height(pbWidth);
      // });
    }
  });
  $(window).on('load',function(){
    if ($('body').hasClass('locations-top')) {
      if ($(window).width()<768) {
        $('.contents h2').on("click", function() {
          $(this).next().slideToggle(
          );
        });
      }
    }
  });
